@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Flama Book';
    src: url('/public/fonts/Flama-Book.otf');
}

* {
    font-family: 'Flama Book';
}

/*I would prefer this was included in tailwind.config.js*/
/*but it's not working as expected*/
@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .scrollbar-slim::-webkit-scrollbar {
        width: 5px;
    }
    .scrollbar-slim::-webkit-scrollbar-thumb {
        background-color: #025578;
        border-radius: 50pt;
    }
    .scrollbar-dropdown {
        scrollbar-width: thin;
        scrollbar-color: #404040 transparent;
        overflow-y: auto;
    }
    .scrollbar-dropdown::-webkit-scrollbar {
        width: 5px;
    }
    .scrollbar-dropdown::-webkit-scrollbar-thumb {
        background-color: #404040;
        border-radius: 50pt;
    }
    .scrollbar-dropdown::-webkit-scrollbar-track {
        background: #e4e4e4;
        border-radius: 50pt;
        margin: 8px 0px 8px 0px;
    }

    .scrollbar-slim-over::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .recharts-responsive-container {
        margin: 0 auto;
    }
    .outline select:focus-within ~ label,
    .outline select:not(:placeholder-shown) ~ label {
        @apply transform scale-75 -translate-y-3.5 z-0 ml-2 px-1 py-0;
    }
    .outline input:not(:placeholder-shown) ~ label,
    .outline textarea:focus-within ~ label,
    .outline textarea:not(:placeholder-shown) ~ label,
    .outline input:focus-within ~ label {
        @apply transform scale-75 -translate-y-7 z-0 ml-2 px-1 py-0;
    }
    .placeholder-label {
        @apply transform scale-75 -translate-y-3.5 z-0 ml-2 px-2 py-0;
    }
    input:focus::-webkit-input-placeholder {
        opacity: 0;
    }
    input:focus::-moz-placeholder {
        opacity: 0;
    }
    *,
    *:focus {
        outline: none;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[value='']::-webkit-datetime-edit,
    input[value='0001-01-01T05:53']::-webkit-datetime-edit,
    input[value='0001-01-01T00:50']::-webkit-datetime-edit {
        color: transparent;
    }
    .dateLabel {
        z-index: 0;
        margin-left: 0.5rem;
        --tw-translate-y: -0.875rem;
        --tw-scale-x: 0.75;
        --tw-scale-y: 0.75;
        transform: var(--tw-transform);
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .mb-20 {
        margin-bottom: 5.2rem;
    }
    .check__ {
        width: 28px;
        height: 27px;
    }
    .check__:checked {
        outline: unset;
    }
    #react-datepicker-portal {
        z-index: 100 !important;
        position: relative;
    }
    .react-datepicker {
        display: flex !important;
        z-index: 1000;
    }
    .date-picker .react-datepicker .react-datepicker__triangle {
        display: none;
    }

    .img__wrap {
        position: relative;
        left: 0px;
    }
    .img__description {
        position: absolute;
        top: 0;
        right: 0;
        --tw-text-opacity: 1;
        color: rgb(55 65 81 / var(--tw-text-opacity));
        display: none;
        opacity: 0;
        transition: opacity 0.2s, visibility 0.2s;
        left: 30px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        background-color: white;
        color: #00789b;
        border: 1px solid black;
        padding: 5px 5px 7px 5px;
        border-radius: 6px;
        font-size: 13px;
        width: 257px;
    }
    .img__wrap img:hover + .img__description {
        display: inline-block;
        z-index: 100;
        opacity: 1;
    }
    .pieParent {
        position: relative;
    }
    .pieBar {
        position: absolute;
        right: 15%;
        top: 40%;
        z-index: 2;
        font-size: 10px;
    }
    .heatmap-occupancy * {
        flex: none !important;
    }
}
.react-transform-component {
    height: 100% !important;
    width: 100% !important;
}
.react-transform-wrapper {
    height: 100% !important;
    width: 100% !important;
}
.guest_form .grid {
    margin-bottom: 1.2rem;
}
.guest_form .grid .outline,
.guest_form .grid.guest_accept > div {
    margin-bottom: 0;
}
.guest_form p.mt-3 {
    margin-top: 3px;
}

/* map-search-bar */

.sc-hLBbgP > input {
    padding-left: 40px !important;
}

/* stacked-charts */
.recharts-default-legend {
    margin-bottom: 15px !important;
}

.gm-style-iw-d > div {
    /* width: 100px; */
    /* height: 100px; */
    width: 100%;
}
.gm-style .gm-style-iw {
    padding: 0px !important;
}
.gm-style .gm-style-iw-c {
    padding: 0;
}

.gm-style .gm-style-iw-d {
    padding: 0;
    overflow: auto !important;
    max-height: 375px !important;
}

.gm-style-iw-ch {
    padding: 0;
}

.gm-ui-hover-effect {
    display: none !important;
}

.gm-style-iw-t {
    bottom: 40px !important;
}

.cmyNjn > .wrapper {
    border-radius: 10px !important;
}

[aria-label='Toggle fullscreen view'] {
    border-radius: 10px !important;
}

.gm-svpc {
    border-radius: 10px !important;
    left: -6px !important;
    width: 50px !important;
    height: 50px !important;
}

.gmnoprint > div {
    border-radius: 10px !important;
}

.gmnoprint > .gm-style-mtc > [title='Show satellite imagery'] {
    border-radius: 0px 10px 10px 0px !important;
    height: 45px !important;
    top: -4px;
}

.gmnoprint > .gm-style-mtc > [title='Show street map'] {
    border-radius: 10px 0px 0px 10px !important;
    height: 45px !important;
    top: -4px;
}
.gm-style .small-cluster img {
    content: url('assets/images/cluster_marker.svg') !important;
    left: 4px !important;
    width: 46px !important;
    top: -6px !important;
}
.gm-style .small-cluster img + div {
    top: -28px !important;
    left: -13px !important;
}

.fade-in {
    animation: fadeIn 0.2s ease-in;
}

.fade-out {
    animation: fadeOut 0.2s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
    background-color: #00547a;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

.grecaptcha-badge {
    position: relative !important;
    height: 0px !important;
    width: 0px !important;
    visibility: hidden;
}

.shadow-right-md {
    box-shadow: 4px 0 4px -1px rgba(0, 0, 0, 0.1),
        3px 0 3px 0px rgba(0, 0, 0, 0.06);
}

/* Active indicator sidebar */
.active-link {
    position: relative;
}

.active-link::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 3px;
    height: 60%;
    background-color: #00789b;
    border-radius: 2px;
}

.active-link::after {
    transition: all 0.3s ease;
}

@media print {
    html,
    body {
        height: 100%; /* Use 100% here to support printing more than a single page*/
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }
}

.responsive-iframe-container {
    position: relative;
    width: 100%;
}

.responsive-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
